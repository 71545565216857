







import {
  ref,
  computed,
  defineComponent, onMounted,
  PropType,
  toRef,
} from '@nuxtjs/composition-api';

import {getID as getProductId } from '~/modules/catalog/product/getters/productGetters';
import SfLoader from '/storefrontUI/components/components/atoms/SfLoader/SfLoader.vue';
import { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'ProductTabs',
  components: {
    SfLoader,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product | null>,
      default: null,
    },
  },
  setup(props) {
    const product = toRef(props, 'product');
    const productID = computed(
      () => getProductId(props?.product),
    );
    const clerk = [
      {
        "template": "@vue-product-page-consigliati-per-te",
      },
      {
        "template": "@vue-product-page-potrebbe-piacerti-anche"
      },
      {
        "template": "@vue-product-page-visti-di-recente"
      }
    ];

    onMounted(async () => {
      setTimeout(function(){
        let clerkScript = document.createElement('script')
        // clerkScript.setAttribute('src', '//cdn.clerk.io/clerk.js')
        clerkScript.setAttribute('src', '/non-indexable/clerk.io.js')
        clerkScript.setAttribute('defer', 'defer')

        document.head.appendChild(clerkScript)
      }, 500);
    });

    return {
      clerk,
      productID
    };
  },
});
